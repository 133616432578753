@font-face {
    font-family: 'Poppins';
    src: local('Poppins'),
        url(assets/fonts/Poppins-Regular.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(assets/fonts/Poppins-Bold.ttf) format('truetype');
    font-weight: 700;
}

/* Hide scrollbars but keep functionality*/
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}
html::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    margin: 0;
    font-family: 'Poppins', 'Ubuntu', -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* Hide up and down arros on input type="number" */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide up and down arrows on input type="number" */
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

html,
body {
    max-width: 100%;
}
